import React from "react"
import Layout from "../components/Layout/Layout"

export default function Home() {
    return (
        <Layout>
            <div className="headerImg-bg pr-0  homepage">

                <div className="d-flex align-items-end vh-100">
                    <div className={"mx-auto"}>
                        <h1 className={"text-white fw-bolder"}>Terapie, cvičení, relaxace, konzultace</h1>
                    </div>
                </div>
            </div>
        </Layout>
    );
}